import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import AnchorWithWrap from "@static/icons/anchor-with-wrap.svg"
import Spyglass from "@static/icons/spyglass.svg"
import Lantern from "@static/icons/lantern.svg"
import Parchment from "@static/icons/parchment.svg"
import LifePreserver from "@static/icons/life-preserver.svg"
import ShipsBell from "@static/icons/ships-bell.svg"

const MainNav = ({ isMainNavOpen, onToggleMenu }) => {
  // Sort the nav menu items using the order field
  // const data = useStaticQuery(graphql`
  //   {
  //     allStrapiNavitem(sort: {fields: order}) {
  //       edges {
  //         node {
  //           id
  //           title
  //           slug
  //           order
  //         }
  //       }
  //     }
  //   }
  // `)

  const data = useStaticQuery(graphql`
    {
      allStrapiNavitem(sort: {fields: order}) {
        edges {
          node {
            id
            title
            slug
            order
            animation
          }
        }
      }
    }
  `)

  return (
    <div id="offcanvas-nav" className={`offcanvas-nav detached off-canvas left ${isMainNavOpen ? "is-active" : ""}`}>
      <div className="grid-content main-nav">
        <button
          className="close-button"
          title="Close Main Navigation"
          aria-label="Close Main Navigation"
          onClick={onToggleMenu}
        >
          ×
        </button>
        <nav
          id="main-nav"
          role="navigation"
          aria-label="Main Navigation"
        >
          <ul>
            {data.allStrapiNavitem.edges.map(item => {
              const navitem = item.node

              const id = navitem.id
              const title = navitem.title ? navitem.title : ""
              const animation = navitem.animation ? navitem.animation : "fade"
              const slug = navitem.slug ? navitem.slug : ""

              let Icon;
              let homepage = false;

              if (slug === "about") {
                Icon = (<AnchorWithWrap />);
              } else if (slug === "projects") {
                Icon = (<Spyglass />);
              } else if (slug === "services") {
                Icon = (<Lantern />);
              } else if (slug === "contact") {
                Icon = (<ShipsBell />);
              } else if (slug === "/" || slug === "") {
                // Change to something else
                Icon = (<LifePreserver />);
                homepage = true;
              } else if (slug === "blog") {
                Icon = (<Parchment />);
              } else if (slug === "ventures") {
                // Change to Octopus
                Icon = (<Parchment />);
              }

              return (
                <li key={id}>
                  <AniLink
                    animation={animation}
                    to={`/${!homepage ? slug :""}`}
                    // onClick={onToggleMenu}
                    duration={1}
                    hex="#00558b"
                    title={title}
                    aria-label={title}
                  >
                    {Icon}
                    <strong>{title}</strong>
                  </AniLink>
                </li>
              )
            })}
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default MainNav





import React from "react"
// import { useStaticQuery, graphql } from "gatsby"
// import SmallShipsWheel from "../../../static/icons/small-ships-wheel.svg"
// import ShipWheel from "@static/icons/ship-wheel.svg"
import IconShipWheel from "./IconShipWheel"

const Header = ({ isMainNavOpen, onToggleMenu, theme }) => {
  // const data = useStaticQuery(graphql`
  //   query HeaderQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  let themeOverride

  if (theme) {
    themeOverride = {
      backgroundColor: theme,
    }
  }

  return (
    <header
      className="grid-content shrink"
      style={{
        padding: 0,
        zIndex: 9999,
      }}
    >
      <ul className="primary condense menu-bar" style={themeOverride}>
        <li>

          <a
            className={`button ng-scope ${isMainNavOpen ? "open" : "closed"}`}
            title={`${isMainNavOpen ? "Close Main Navigation" : "Open Main Navigation"}`}
            aria-label={`${isMainNavOpen ? "Close Main Navigation" : "Open Main Navigation"}`}
            onClick={onToggleMenu}
            role="button"
            style={themeOverride}
          >
            {/*<ShipWheel />*/}
            <IconShipWheel theme={theme} />
          </a>

          {/*
          <button
            className={`button ng-scope ${isMainNavOpen ? "open" : "closed"}`}
            title={`${isMainNavOpen ? "Close Main Navigation" : "Open Main Navigation"}`}
            aria-label={`${isMainNavOpen ? "Close Main Navigation" : "Open Main Navigation"}`}
            onClick={onToggleMenu}
            styles="margin: 0; padding: 1rem;"
          >
            <ShipWheel />
          </button>
          */}
        </li>
      </ul>
    </header>
  )
}

export default Header

import React, { useState } from "react"
import PropTypes from "prop-types"

import MainNav from "@components/Nav/MainNav"
import Header from "@components/Header/Header"

import "@scss/klmcgregor.min.css"

const Layout = ({ children, theme }) => {
  // const { site } = useStaticQuery(
  //   graphql`
  //     query {
  //       site {
  //         siteMetadata {
  //           title
  //           description
  //           author
  //         }
  //       }
  //     }
  //   `
  // )
  //
  // if (typeof window !== "undefined") {
  //   // console.log(`Location: ${window.location.href}`);
  //
  //   window.onblur = function () { document.title = `⛵⚓`; }
  //
  //   // Zombie [¬º-°]¬
  //   // Flip table (╯°□°）╯︵ ┻━┻
  //   // Flip all tables ┻━┻︵ \(°□°)/ ︵ ┻━┻
  //   // coffee  c[_]
  //   // deal with it ( •_•)>⌐■-■ (⌐■_■)
  //   // dog ˁ˚ᴥ˚ˀ
  //   // sleeping (-.-)Zzz...
  //   // canoe ~~~~╘══╛~~~~
  //   // snowing ✲´*。.❄¨¯`*✲。❄。*。
  //   // wat ಠ_ಠ
  //   // robot d[o_0]b
  //   // headphones d[-_-]b
  //   // fix table (ヘ･_･)ヘ┳━┳
  //   // wizard (∩ ͡° ͜ʖ ͡°)⊃━☆ﾟ. *
  //   // shrug ¯\_(ツ)_/¯
  //   // 🍕
  //   // 🍕🍍
  //
  //
  //   window.onfocus = function () { document.title = site.siteMetadata.title; }
  // }

  const [ isMainNavOpen, setIsMainNavOpen ] = useState(false);

  return (
    <>
      <MainNav
        isMainNavOpen={isMainNavOpen}
        onToggleMenu={() => setIsMainNavOpen(!isMainNavOpen)}
      />
      <div
        className="grid-frame vertical"
        // style={{
        //   margin: `0 auto`,
        //   maxWidth: 960,
        //   padding: `0px 1.0875rem 1.45rem`,
        //   paddingTop: 0,
        // }}
      >
        <Header
          isMainNavOpen={isMainNavOpen}
          onToggleMenu={() => setIsMainNavOpen(!isMainNavOpen)}
          theme={theme}
        />
        {children}
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
